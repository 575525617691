import React from 'react';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import { Panels } from '../components/Panels';
import { graphql, Link } from 'gatsby';
import { Hero } from '../components/Hero';
import { Seo } from '../components/Seo';

const ForPatients: Page = ({ data: { items } }) => {
    return (
        <>
            <Seo
                title="For Patients | Clermont Kids Dentistry, P.A."
                description='At Clermont Kids Dentistry, we strive to assist families in raising children have incredibly fun dental patients. "Because every TOOTH matters!'
            />
            <main>
                <Hero type="for patients" title="For Patients">
                    <StaticImage
                        alt="girl putting her finger on her teeth"
                        className="hero-image"
                        src="../images/for-patients-hero.jpeg"
                    />
                </Hero>
                <Panels>
                    {items.edges.map(service => {
                        if (service.node.frontmatter.title === 'New Patient Form') {
                            return (
                                <a
                                    href="https://clermontkidsdentistry.com/sites/clermontkidsdentistry.com/files/forms/new-patient-form-ckd.pdf"
                                    target="__blank"
                                    rel="noopener norefferer"
                                    key={service.node.frontmatter.title}
                                >
                                    <GatsbyImage
                                        alt={service.node.frontmatter.thumbnail.name}
                                        image={
                                            service.node.frontmatter.thumbnail.childImageSharp
                                                .gatsbyImageData
                                        }
                                    />
                                    <p>{service.node.frontmatter.title}</p>
                                </a>
                            );
                        } else {
                            return (
                                <Link
                                    key={service.node.frontmatter.title}
                                    to={service.node.frontmatter.slug}
                                >
                                    <GatsbyImage
                                        alt={service.node.frontmatter.thumbnail.name}
                                        image={
                                            service.node.frontmatter.thumbnail.childImageSharp
                                                .gatsbyImageData
                                        }
                                    />
                                    <p>{service.node.frontmatter.title}</p>
                                </Link>
                            );
                        }
                    })}
                </Panels>
            </main>
        </>
    );
};

export const query = graphql`
    query {
        items: allMdx(filter: { frontmatter: { type: { eq: "for patients" } } }) {
            edges {
                node {
                    id
                    frontmatter {
                        type
                        images {
                            childImageSharp {
                                gatsbyImageData(quality: 100, placeholder: NONE)
                            }
                            name
                        }
                        title
                        slug
                        thumbnail {
                            childImageSharp {
                                gatsbyImageData(quality: 100, placeholder: NONE)
                            }
                            name
                        }
                    }
                }
            }
        }
    }
`;

export default ForPatients;
